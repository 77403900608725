import {apiGetCall, apiPostCall} from "./index";

export const load               = () => apiGetCall("/caretakers/getall");
export const loadMachineDetails = uuid => apiGetCall("/caretakers/machines", {uuid});
export const add                = caretakerDetails => apiPostCall("/caretakers/add", {...caretakerDetails}, true, true);
export const checkScreenCode    = screenCode => apiPostCall("/caretakers/checkscreencode", {screenCode});
export const save               = (uuid, caretakerDetails) => apiPostCall("/caretakers/save", {uuid, ...caretakerDetails});
export const savePhoto          = (uuid, file) => apiPostCall("/caretakers/savephoto", {uuid, file}, true, true);
export const removePhoto        = uuid => apiPostCall("/caretakers/removephoto", {uuid});
export const saveSettings       = (uuid, settings) => apiPostCall("/caretakers/settings", {uuid, ...settings});
export const saveDefaults       = (uuid, defaults) => apiPostCall("/caretakers/defaults", {uuid, ...defaults});
export const saveMachineName    = (uuid, serial, settings) => apiPostCall("/caretakers/savemachinename", {uuid, serial, ...settings});
export const remove             = uuid => apiPostCall("/caretakers/remove", {uuid});
export const invite             = userDetails => apiPostCall("/caretakers/inviteuser", {...userDetails});