import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import * as actions from "../../../../store/actions";

import i18n from "../../../../i18n";
import tableColumns from "../../../../variables/tableColumns";

import {ColumnSelector, Spinner, CollapseCard, ReactTable} from "../../../../components";

class Updates extends Component {
    state = {
        columns: null
    };

    cancelUpdate = id => this.props.cancel(id);
    tableColumns = tableColumns.updateColumnsDefault([
        {
            color:  "danger",
            icon:   "e-remove",
            action: this.cancelUpdate,
            text:   i18n.buttonCancel
        }
    ]);

    constructor(props) {
        super(props);
        this.state.columns = this.tableColumns;
    }

    render       = () => (
        <CollapseCard title={i18n.machineInfoUpdatesHead} defaultOpenDesktop>
            <ColumnSelector cookieId="machineUpdates" columns={this.state.columns} changed={columns => this.setState({columns})}/>
            <ReactTable
                filterable
                data={this.props.updates}
                columns={this.state.columns}
                noDataText={i18n.machineNoUpdates}
                indexKey="id"
                defaultSorted={[{id: "id", desc: true}]}
            />
            {this.props.cancelling && <Spinner/>}
        </CollapseCard>
    );
}

Updates.propTypes = {
    updates: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    loading:     state.machines.loading,
    cancelling:  state.machines.cancellingUpdate,
    loadedAt:    state.machines.loadedAt,
    loadedFor:   state.machines.loadedFor,
    machineInfo: state.machines.machineInfo
});

const mapDispatchToProps = dispatch => ({
    cancel: id => dispatch(actions.machines.cancelUpdate(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Updates);