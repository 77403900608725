// React
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
//import initReactFastclick from './tools/react-fastclick';

// Redux
import {Provider} from 'react-redux';

// Styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/now-ui-dashboard.css";
import "./assets/css/demo.css";

// Components
import App from "./App";
import "./variables/defaults";
import {thisIsAnApp} from "./variables/multiplatform";

// Setup Redux
import * as actions from "./store/actions";
import {history, store} from "./store";

// App starter
const startApp = () => {
    //initReactFastclick();

    // Render app
    ReactDOM.render((
        <Provider store={store}>
            <App history={history}/>
        </Provider>
    ), document.getElementById("root"));

    if (thisIsAnApp) {
        if (window.StatusBar) {
            window.StatusBar.overlaysWebView(false);
            setImmediate(() => {window.StatusBar.overlaysWebView(true)});
        }
        if (window.universalLinks) {
            window.universalLinks.subscribe(null, eventData => {
                history.push(eventData.path);
                store.dispatch(actions.app.receivedUniversalLink(eventData));
            });
        }
    }

    serviceWorker.register();
};

// Check how to start the app (is this Phonegap or is this a browser?)
if (thisIsAnApp) {
    document.addEventListener('deviceready', startApp);
} else {
    startApp();
}
