import * as actionTypes from "../actionTypes";

export const load        = forGroup => ({type: actionTypes.GROUP_CALENDARS_LOAD, forGroup});
export const loadSuccess = (forGroup, calendars) => ({type: actionTypes.GROUP_CALENDARS_LOAD_SUCCESS, forGroup, calendars});
export const loadFail    = () => ({type: actionTypes.GROUP_CALENDARS_LOAD_FAIL});

export const saveAppointment = (forGroup, appointment) => ({type: actionTypes.GROUP_CALENDAR_SAVE_APPOINTMENT, forGroup, appointment});
export const saveBirthday    = (forGroup, birthday) => ({type: actionTypes.GROUP_CALENDAR_SAVE_BIRTHDAY, forGroup, birthday});
export const saveReminder    = (forGroup, reminder) => ({type: actionTypes.GROUP_CALENDAR_SAVE_REMINDER, forGroup, reminder});
export const saveTvShow      = (forGroup, tvShow) => ({type: actionTypes.GROUP_CALENDAR_SAVE_TVSHOW, forGroup, tvShow});
export const saveSuccess     = () => ({type: actionTypes.GROUP_CALENDAR_SAVE_SUCCESS});
export const saveFail        = () => ({type: actionTypes.GROUP_CALENDAR_SAVE_FAIL});
export const clearSend       = () => ({type: actionTypes.GROUP_CALENDAR_SAVE_CLEAR});

export const skip        = (forGroup, calendarType, uuid) => ({type: actionTypes.GROUP_CALENDAR_SKIP, forGroup, calendarType, uuid});
export const unskip      = (forGroup, calendarType, uuid) => ({type: actionTypes.GROUP_CALENDAR_UNSKIP, forGroup, calendarType, uuid});
export const skipSuccess = () => ({type: actionTypes.GROUP_CALENDAR_SKIP_SUCCESS});
export const skipFail    = () => ({type: actionTypes.GROUP_CALENDAR_SKIP_FAIL});

export const remove        = (forGroup, calendarType, uuid) => ({type: actionTypes.GROUP_CALENDAR_REMOVE, forGroup, calendarType, uuid});
export const removeSuccess = () => ({type: actionTypes.GROUP_CALENDAR_REMOVE_SUCCESS});
export const removeFail    = () => ({type: actionTypes.GROUP_CALENDAR_REMOVE_FAIL});

export const clear = () => ({type: actionTypes.GROUP_CALENDAR_LIST_CLEAR});