import * as actionTypes from "../actionTypes";

export const load        = forCaretaker => ({type: actionTypes.MESSAGES_LOAD, forCaretaker});
export const loadSuccess = (forCaretaker, messages) => ({type: actionTypes.MESSAGES_LOAD_SUCCESS, forCaretaker, messages});
export const loadFail    = () => ({type: actionTypes.MESSAGES_LOAD_FAIL});

export const send        = (destination, message, duration, urgent) => ({type: actionTypes.MESSAGE_SEND, destination, message, duration, urgent});
export const sendSuccess = () => ({type: actionTypes.MESSAGE_SEND_SUCCESS});
export const sendFail    = () => ({type: actionTypes.MESSAGE_SEND_FAIL});
export const clearSend   = () => ({type: actionTypes.MESSAGE_SEND_CLEAR});

export const cancel        = (forCaretaker, uuid) => ({type: actionTypes.MESSAGE_CANCEL, forCaretaker, uuid});
export const cancelSuccess = () => ({type: actionTypes.MESSAGE_CANCEL_SUCCESS});
export const cancelFail    = () => ({type: actionTypes.MESSAGE_CANCEL_FAIL});

export const clear = () => ({type: actionTypes.MESSAGE_LIST_CLEAR});