export default {
    locationsTitle: "Alle pleje modtager steder",

    caretakers:   "Pleje modtagere",
    noCaretakers: "Ingen pleje modtagere",

    caretaker:                    "Pleje modtager",
    caretakersTitle:              "Pleje modtagere",
    caretakersTableName:          "Navn",
    caretakersTableLocation:      "Sted",
    caretakersTableAddress:       "Adresse",
    caretakersTableZipCode:       "Postnummer",
    caretakersTableCity:          "By",
    caretakersTableState:         "Provins",
    caretakersTableCountry:       "Land",
    caretakersTableTimeZone:      "Tidszone",
    caretakersTableMachines:      "Maskiner",
    caretakersTableMachinesShort: "M",
    caretakersActionsEdit:        "Rediger",
    caretakersActionsDelete:      "Fjern",

    caretakersFullTableName:     "Pleje modtager navn",
    caretakersFullTableLocation: "Pleje modtager sted",
    caretakersFullTableAddress:  "Pleje modtager adresse",
    caretakersFullTableZipCode:  "Pleje modtager postnummer",
    caretakersFullTableCity:     "Pleje modtager by",
    caretakersFullTableState:    "Pleje modtager provins",
    caretakersFullTableCountry:  "Pleje modtager land",

    caretakersAddNewMachine:                "Tilføj en ny Spiqle",
    caretakersAddNewMachineTitle:           "Tilføj Spiqle",
    caretakersAddNewMachineScreenCodeLabel: "Skærmkode",
    caretakersAddNewMachineScreenName:      "Skærms navn",
    caretakersAddNewMachineStep2:           "Pleje modtager",
    caretakersAddNewMachineStep3:           "Resumé",
    caretakersAddNewMachineScreenCode:      "Indtast den kode, der vises på skærmen",
    caretakersAddNewMachineCaretaker:       "Vælg en eksisterende pleje modtager eller tilføj en ny pleje modtager",
    caretakersAddNewMachineSuccess:         "Den nye Spiqle Agenda er blevet tilføjet. Du kan nu begynde at indstille indholdet.",
    caretakersAddNewMachineError:           "Der opstod en fejl under tilknytningen af skærmen",
    caretakersAddNewMachineInvalidCode:     "Ukendt skærmkode, kontroller skærmkoden og prøv igen",
    caretakersAddNewMachineToExisting:      "Tilføj ny Spiqle til denne pleje modtager",


    caretakersAddMenu: "Tilføj...",

    caretakersAddNewUser:                  "Tilføj bruger",
    caretakersAddNewUserExplain:           "Med denne funktion kan du invitere andre brugere til at få adgang til dine pleje modtagere. Således kan du for eksempel dele plejen med et familiemedlem eller en kollega.",
    caretakersAddNewUserName:              "Navn",
    caretakersAddNewUserEmail:             "E-mail",
    caretakersAddNewUserCaretakers:        "Adgang til:",
    caretakersAddNewUserRights:            "Brugerrettigheder",
    caretakersAddNewUserRightsList:        {
        content: "Kalender",
        users:   "Kalender og brugere",
        payment: "Kalender og administration"
    },
    caretakersInviteSuccess:               "Den nye bruger er blevet inviteret med succes",
    caretakersInviteError:                 "Der opstod en fejl under invitationen af den nye bruger",
    caretakersInviteErrorInvitedYourself:  "Du kan ikke invitere dig selv",
    caretakersInviteErrorUnknownRight:     "Ukendte brugerrettigheder",
    caretakersInviteErrorInvalidCaretaker: "Ukendt pleje modtager",

    caretakersItemAdd:         "Tilføj pleje modtager",
    caretakersItemEdit:        "Ændre pleje modtagers data",
    caretakersItemEditSuccess: "Pleje modtagers data er blevet gemt med succes",
    caretakersItemEditError:   "Der opstod en fejl under gemningen af pleje modtagers data",

    caretakersAddMachine: "Tilføj Spiqle skærm",

    caretakersMessage:              "Direkte besked",
    caretakersMessageTo:            "Direkte besked til %1",
    caretakersMessageSentTo:        "Send en besked til %1",
    caretakersMessageMessage:       "Besked",
    caretakersMessageDuration:      "Synlig for:",
    caretakersMessageDurations:     {
        300:   "5 minutter",
        900:   "15 minutter",
        1800:  "30 minutter",
        3600:  "1 time",
        10800: "3 timer",
        21600: "6 timer",
        43200: "12 timer",
        86400: "24 timer"
    },
    caretakersMessageUrgent:        "Urgent besked",
    caretakersMessageSuccess:       "Direkte besked sendt med succes",
    caretakersMessageError:         "Der opstod en fejl under afsendelse af den direkte besked",
    caretakersMessageCancelSuccess: "Direkte besked er blevet fjernet fra skærmen med succes",
    caretakersMessageCancelError:   "Der opstod en fejl under fjernelse af den direkte besked",
    caretakersMessageNoMachines:    "Denne pleje modtager har ingen skærme endnu",
    buttonCancelMessage:            "Annuller besked (fjern fra skærm)",

    caretakersSentMessages:          "Besked oversigt",
    caretakersSentMessagesMessage:   "Besked",
    caretakersSentMessagesSentLong:  "Sendt",
    caretakersSentMessagesSentShort: "Sendt",
    caretakersSentMessagesSentBy:    "Sendt af",
    caretakersSentMessagesDelivered: "Vist på skærm",
    caretakersSentMessagesCancelled: "Besked annulleret",
    caretakersNoSentMessages:        "Ingen beskeder sendt endnu",

    caretakersItemCalendar:                      "Kalender",
    caretakersItemCalendars:                     "Kalendere",
    caretakersItemCalendarsDefaultSettings:      "Standardindstillinger for kalendere",
    caretakersItemCalendarsEditAppointments:     "Administrer aftaler",
    caretakersItemCalendarsEditBirthdays:        "Administrer fødselsdage",
    caretakersItemCalendarsEditTvShows:          "Administrer TV-programmer",
    caretakersItemCalendarsEditReminders:        "Administrer påmindelser",
    caretakersItemCalendarsEditItemAppointments: "Rediger aftale",
    caretakersItemCalendarsEditItemBirthdays:    "Rediger fødselsdag",
    caretakersItemCalendarsEditItemTvShows:      "Rediger TV-program",
    caretakersItemCalendarsEditItemReminders:    "Rediger påmindelse",
    caretakersItemCalendarsFor:                  "Administrer kalendere for %1",
    caretakersItemPreview:                       "Skærmforhåndsvisning",
    caretakersItemPreviewFor:                    "Skærmforhåndsvisning for %1",
    caretakersItemMachines:                      "Administrer maskiner for %1",
    caretakersItemMachinesTitle:                 "Administrer maskiner",
    caretakersItemMachinesAdd:                   "Tilknyt maskine til denne pleje modtager",
    caretakersSelectCaretaker:                   "Vælg en pleje modtager",
    caretakerChooseAddOrExisting:                "Vælg en eksisterende plejemodtager eller tilføj en ny.",
    caretakerCreateNewTitle:                     "Voer de gegevens van de nieuwe zorgvrager toe",
    caretakerCreateNewCaretaker:                 "Indtast data for den nye pleje modtager",

    caretakersRemoveTitle:   "Fjern pleje modtager",
    caretakersRemoveBody:    "Hvis du fjerner pleje modtageren med navnet '%1', bliver alle maskiner, der i øjeblikket er tilknyttet denne pleje modtager, afkoblet. Dette kan ikke fortrydes.",
    caretakersRemoveSure:    "Fjern pleje modtager",
    caretakersRemoveSuccess: "Pleje modtageren er blevet fjernet med succes",
    caretakersRemoveError:   "Der opstod en fejl under fjernelse af pleje modtageren",

    caretakersDayOverview:      "Dags oversigt",
    caretakersDayOverviewFor:   "Dags oversigt for %1",
    caretakersWeekOverviewFor:  "Dagens oversigt",
    caretakersMonthOverviewFor: "Dagens oversigt for %1",
    caretakersOverviewTime:     "Tid",
    caretakersOverviewNoItems:  "Der er ingen kalender emner denne dag",

    caretakerSettings:    "Indstillinger",
    caretakerSettingsFor: "Administrer indstillingerne for %1",

    caretakerDetails:           "Data %1",
    caretakerScreenSettings:    "Skærmindstillinger",
    caretakerScreenStatus:      "Spiqle Skærme",
    caretakerScreenStatusTitle: "Skærme %1",
    caretakerScreenName:        "Skærms navn:",
    caretakerScreenStatusHead:  "Skærms status:",

    caretakerSettingsTheme:                          "Tema",
    caretakerSettingsThemeValues:                    {
        black:               "sort",
        blackboard:          "kridt",
        emeraldgreen:        "mørkegrøn",
        lemongrass:          "limegræs",
        nightblue:           "mørkeblå",
        papaver:             "valmue",
        pumpkin:             "græskar",
        steelblue:           "stålblå",
        yellow:              "kontrast gul",
        sockgreenolive:      "grøn sok: Oliven",
        sockgreenkhaki:      "grøn sok: Khaki",
        sockdewcorn:         "dug sok: Majs silke",
        sockdewwheat:        "dug sok: Hvede",
        sockperuyellowbrown: "peru sok: Gylden brun",
        sockperusienna:      "peru sok: Sienna",
        modernblue:          "moderne - Blå",
        modernbrown:         "moderne - Brun",
        moderngreen:         "moderne - Grøn",
        moderndeepsea:       "moderne - Dybhav",
        modernblack:         "moderne - Sort"
    },
    caretakerSettingsFont:                           "Skrifttype",
    caretakerSettingsFontValues:                     {
        helvetica: "Helvetica",
        calibri:   "Calibri",
        gillsans:  "Gill sans"
    },
    caretakerSettingsLanguage:                       "Sprog",
    caretakerSettingsLanguageValues:                 {
        nl: "Hollandsk",
        da: "Dansk",
        de: "Tysk",
        en: "Engelsk"
    },
    caretakerSettingsGreeting:                       "Hilsen",
    caretakerSettingsClock:                          "Ur",
    caretakerSettingsClockValues:                    {
        digital:    "digital 11:00",
        digitalext: "digital 11:00 Morgen",
        analog:     "analog  🕘",
        written:    "udskrevet (Fem over elleve)"
    },
    caretakerSettingsDAT:                            "Vis i overmorgen / om 2 dage",
    caretakerSettingsDATValues:                      {
        dat:     "i overmorgen",
        "2days": "om 2 dage"
    },
    caretakerSettingsHours:                          "24 / 12 timer",
    caretakerSettingsHoursValues:                    {
        12: "12 timers ur",
        24: "24 timers ur"
        /*        12: "12 timers ur (kvarter over 2)",
                24: "24 timers ur (14:15)"*/
    },
    caretakerSettingsSpeed:                          "Beskedens varighed",
    caretakerSettingsSpeedValues:                    {
        1: "hurtig (10 sekunder)",
        2: "normal (20 sekunder)",
        3: "langsom (40 sekunder)",
        4: "meget langsom (60 sekunder)",
        5: "endnu langsommere (120 sekunder)"
    },
    caretakerSettingsTomorrowDay:                    "I morgen",
    caretakerSettingsTomorrowDayYes:                 "Vis dagen som en aftale i morgen",
    caretakerSettingsTomorrowDayNo:                  "Vis ikke dagen som en aftale i morgen",
    caretakerSettingsItIsToday:                      "Vis i dag",
    caretakerSettingsItIsTodayYes:                   "Vis 'i dag' i hilsenen",
    caretakerSettingsItIsTodayNo:                    "Vis ikke 'i dag' i hilsenen",
    caretakerSettingsShowHourToday:                  "Vis 'time' efter tid",
    caretakerSettingsShowHourTodayYes:               "Vis 'time' efter tid",
    caretakerSettingsShowHourTodayNo:                "Vis ikke 'time' efter tid",
    caretakerSettingsShowCountdown:                  "Nedtælling til aftale (x timer tilbage)",
    caretakerSettingsShowCountdownYes:               "Nedtælling til aftale (x timer tilbage)",
    caretakerSettingsShowCountdownNo:                "Ingen nedtælling til aftale",
    caretakerSettingsShowWeather:                    "Vejrudsigt for nu",
    caretakerSettingsShowWeatherYes:                 "Vis det nuværende vejr",
    caretakerSettingsShowWeatherNo:                  "Vis ikke det nuværende vejr",
    caretakerSettingsTomorrowWeather:                "Vejrudsigt for i morgen",
    caretakerSettingsTomorrowWeatherYes:             "Vis vejret for i morgen",
    caretakerSettingsTomorrowWeatherNo:              "Vis ikke vejret for i morgen",
    caretakerSettingsPublicHolidaysShowBefore:       "Vis helligdage fra",
    caretakerSettingsPublicHolidaysShowBeforeValues: {
        "15 minutes": "Dagen selv",
        "1 day":      "1 dag i forvejen",
        "2 days":     "2 dage i forvejen",
        "3 days":     "3 dage i forvejen",
        "4 days":     "4 dage i forvejen",
        "5 days":     "5 dage i forvejen",
        "6 days":     "6 dage i forvejen",
        "1 week":     "1 uge i forvejen"
    },
    caretakerSettingsPublicHolidaysCountry:          "Vis helligdage for",
    caretakerSettingsPublicHolidaysCountryValues:    {
        nl: "Holland",
        be: "Belgien",
        da: "Danmark",
        de: "Tyskland",
        ch: "Schweiz",
        uk: "Storbritannien"
    },

    caretakerSettingsPublicHolidays:    "Helligdage",
    caretakerSettingsPublicHolidaysYes: "Vis helligdage",
    caretakerSettingsPublicHolidaysNo:  "Vis ingen helligdage",

    caretakerSettingsNotificationSound: "Notifikationslyd",
    caretakerSettingsNotificationYes:   "Lyde ved meddelelser",
    caretakerSettingsNotificationNo:    "Ingen lyde ved meddelelser",
    caretakerSettingsErrorLoad:         "Der opstod en fejl ved indlæsning af plejemodtagerens indstillinger",
    caretakerSettingsErrorSave:         "Der opstod en fejl ved gemning af plejemodtagerens indstillinger",
    caretakerSettingsSuccessSave:       "Plejemodtagerens indstillinger er gemt succesfuldt",

    caretakerDefaultsErrorLoad:   "Der opstod en fejl ved indlæsning af plejemodtagerens standardindstillinger",
    caretakerDefaultsErrorSave:   "Der opstod en fejl ved gemning af plejemodtagerens standardindstillinger",
    caretakerDefaultsSuccessSave: "Plejemodtagerens standardindstillinger er gemt succesfuldt",

    caretakerMachineNameErrorSave:   "Der opstod en fejl ved gemning af skærmnavnet",
    caretakerMachineNameSuccessSave: "Skærmnavnet er gemt succesfuldt",

    caretakerName:               "Navn",
    caretakerAddress:            "Adresse",
    caretakerZipcode:            "Postnummer",
    caretakerCity:               "By",
    caretakerState:              "Provins",
    caretakerCountry:            "Land",
    caretakerPhone:              "Telefonnummer",
    caretakerPhoto:              "Billede",
    caretakerPhotoPick:          "Vælg andet billede",
    caretakerPhotoRemove:        "Slet billede",
    caretakerBirthday:           "Fødselsdato",
    caretakerShowBirthday:       "Vis fødselsdato i kalender",
    caretakerShowBirthdayYes:    "Vis i kalenderoversigt",
    caretakerShowBirthdayNo:     "Vis ikke i kalenderoversigt",
    errorCaretakersLoad:         "Der opstod en fejl ved indlæsning af plejemodtagerens data",
    caretakersLoadMachinesError: "Der opstod en fejl ved indlæsning af plejemodtagerens maskiner",

    photoAdd:    "Tilføj",
    photoEdit:   "Erstat",
    photoDelete: "Slet",
};