import {takeLatest} from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

// Watcher
export function* watchApp() {
    yield takeLatest(actionTypes.APP_RECEIVED_UNIVERSAL_LINK, receivedUniversalLinkSaga);
}

// Sagas
function* receivedUniversalLinkSaga(action) {
}

