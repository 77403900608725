import * as actionTypes from "../actionTypes";

export const load        = forGroup => ({type: actionTypes.GROUP_MESSAGES_LOAD, forGroup});
export const loadSuccess = (forGroup, messages) => ({type: actionTypes.GROUP_MESSAGES_LOAD_SUCCESS, forGroup, messages});
export const loadFail    = () => ({type: actionTypes.GROUP_MESSAGES_LOAD_FAIL});

export const send        = (destination, message, duration, urgent) => ({type: actionTypes.GROUP_MESSAGE_SEND, destination, message, duration, urgent});
export const sendSuccess = () => ({type: actionTypes.GROUP_MESSAGE_SEND_SUCCESS});
export const sendFail    = () => ({type: actionTypes.GROUP_MESSAGE_SEND_FAIL});
export const clearSend   = () => ({type: actionTypes.GROUP_MESSAGE_SEND_CLEAR});

export const cancel        = (forGroup, uuid) => ({type: actionTypes.GROUP_MESSAGE_CANCEL, forGroup, uuid});
export const cancelSuccess = () => ({type: actionTypes.GROUP_MESSAGE_CANCEL_SUCCESS});
export const cancelFail    = () => ({type: actionTypes.GROUP_MESSAGE_CANCEL_FAIL});

export const clear = () => ({type: actionTypes.GROUP_MESSAGE_LIST_CLEAR});