import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, loadFail, save, saveDone, saveFail, resetError} from "../reducerFunctions";

const initialState = {
    loading:     false,
    saving:      false,
    error:       null,
    language:    "",
    items:       null,
    itemsLoaded: null
};

// Load reducers
const load        = (state, action) => ({...state, loading: true, language: action.language});
const loadAll     = (state, action) => ({...state, loading: true, language: "all"});
const loadSuccess = (state, action) => ({...state, loading: false, error: null, items: action.items, itemsLoaded: moment()});

const actionMap   = {
    [actionTypes.HELP_LOAD]:           load,
    [actionTypes.HELP_LOAD_ALL]:       loadAll,
    [actionTypes.HELP_LOAD_SUCCESS]:   loadSuccess,
    [actionTypes.HELP_LOAD_FAIL]:      loadFail,
    [actionTypes.HELP_SAVE]:           save,
    [actionTypes.HELP_SAVE_SUCCESS]:   saveDone,
    [actionTypes.HELP_SAVE_FAIL]:      saveFail,
    [actionTypes.HELP_REMOVE]:         save,
    [actionTypes.HELP_REMOVE_SUCCESS]: saveDone,
    [actionTypes.HELP_REMOVE_FAIL]:    saveFail,
    [actionTypes.HELP_RESET_ERROR]:    resetError
};
const helpReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default helpReducer;
