import * as actionTypes from "../actionTypes";

export const load        = forCaretaker => ({type: actionTypes.CALENDARS_LOAD, forCaretaker});
export const loadSuccess = (forCaretaker, calendars) => ({type: actionTypes.CALENDARS_LOAD_SUCCESS, forCaretaker, calendars});
export const loadFail    = () => ({type: actionTypes.CALENDARS_LOAD_FAIL});

export const saveAppointment = (forCaretaker, appointment) => ({type: actionTypes.CALENDAR_SAVE_APPOINTMENT, forCaretaker, appointment});
export const saveBirthday    = (forCaretaker, birthday) => ({type: actionTypes.CALENDAR_SAVE_BIRTHDAY, forCaretaker, birthday});
export const saveReminder    = (forCaretaker, reminder) => ({type: actionTypes.CALENDAR_SAVE_REMINDER, forCaretaker, reminder});
export const saveTvShow      = (forCaretaker, tvShow) => ({type: actionTypes.CALENDAR_SAVE_TVSHOW, forCaretaker, tvShow});
export const saveSuccess     = () => ({type: actionTypes.CALENDAR_SAVE_SUCCESS});
export const saveFail        = () => ({type: actionTypes.CALENDAR_SAVE_FAIL});
export const clearSend       = () => ({type: actionTypes.CALENDAR_SAVE_CLEAR});

export const skip        = (forCaretaker, calendarType, uuid) => ({type: actionTypes.CALENDAR_SKIP, forCaretaker, calendarType, uuid});
export const unskip      = (forCaretaker, calendarType, uuid) => ({type: actionTypes.CALENDAR_UNSKIP, forCaretaker, calendarType, uuid});
export const skipSuccess = () => ({type: actionTypes.CALENDAR_SKIP_SUCCESS});
export const skipFail    = () => ({type: actionTypes.CALENDAR_SKIP_FAIL});

export const remove        = (forCaretaker, calendarType, uuid) => ({type: actionTypes.CALENDAR_REMOVE, forCaretaker, calendarType, uuid});
export const removeSuccess = () => ({type: actionTypes.CALENDAR_REMOVE_SUCCESS});
export const removeFail    = () => ({type: actionTypes.CALENDAR_REMOVE_FAIL});

export const clear = () => ({type: actionTypes.CALENDAR_LIST_CLEAR});