export default {
    calendarTypes:        {
        appointments: "Termine",
        birthdays:    "GeburtsTage",
        reminders:    "Erinnerungen",
        tvshows:      "Fernsehprogramme"
    },
    calendarTypeSingular: {
        appointment: "Termin",
        birthday:    "Geburtstag",
        reminder:    "Erinnerung",
        tvshow:      "Fernsehprogramm"
    },
    calendarManageTitle:  {
        appointments: "Verwalten Sie die Termine für %1",
        birthdays:    "Verwalten Sie die GeburtsTage für %1",
        reminders:    "Verwalten Sie die Erinnerungen für %1",
        tvshows:      "Verwalten Sie die Erinnerungen für %1"
    },
    calendarAddTitle:     {
        appointments: "Termin für %1 hinzufügen",
        birthdays:    "Füge Geburtstag für %1 hinzu",
        reminders:    "Erinnerungen für %1 hinzufügen",
        tvshows:      "Fernsehprogramm für %1 hinzufügen"
    },
    calendarEditTitle:    {
        appointments: "Ändern Sie den Termin für %1",
        birthdays:    "Ändere Geburtstag für %1",
        reminders:    "Erinnerungen für %1 ändern",
        tvshows:      "Ändern Sie das Fernsehprogramm für %1"
    },

    calendarOverview:           "Kalenderübersicht %1",
    calendarDay:                "%1",
    calendarAllItems:           "Alle geplanten Artikel anzeigen",
    calendarLoadError:          "Beim Laden des Kalenders ist ein Fehler aufgetreten",
    calendarAddItem:            "Neuen Eintrag hinzufügen",
    calendarAddItemFor:         "Neuen Eintrag hinzufügen für %1",
    calendarDeleteItem:         "Eintrag löschen",
    calendarDeleteTitle:        "Möchten Sie diesen Eintrag wirklich löschen?",
    calendarDeleteBody:         "Möchten Sie diesen Eintrag '% 1' wirklich löschen?",
    calendarDeleteOverviewBody: "Möchten Sie diesen Eintrag '% 1' wirklich löschen? Dadurch wird der gesamte Eintrag entfernt, nicht nur für diesen Tag!",
    calendarDeleteSuccess:      "Eintrag erfolgreich gelöscht",
    calendarDeleteError:        "Beim Löschen des Eintrags ist ein Fehler aufgetreten",
    calendarDefaultSettings:    "Standardeinstellungen",

    calendarDayOverview: "Überblick",
    calendarDayTimeline: "Zeitleiste",

    calendarSaveSuccess: {
        appointments: "Termin erfolgreich gespeichert",
        birthdays:    "Geburtstag erfolgreich gespeichert",
        reminders:    "Erinnerung erfolgreich gespeichert",
        tvshows:      "Fernsehprogramm erfolgreich gespeichert"
    },
    calendarSaveFail:    {
        appointments: "Termin kann nicht gespeichert werden",
        birthdays:    "Geburtstag kann nicht gespeichert werden",
        reminders:    "Erinnerung kann nicht gespeichert werden",
        tvshows:      "Fernsehprogramm kann nicht gespeichert werden"
    },

    calendarEditFormTitle:              "Nachricht",
    calendarEditFormShowName:           "Programmtitel",
    calendarEditFormChannel:            "Kanal",
    calendarEditWholeDay:               "Den ganzen Tag",
    calendarEditWholeDayYes:            "Dauert den ganzen Tag",
    calendarEditWholeDayNo:             "Dauert nicht den ganzen Tag",
    calendarEditFormDate:               "Datum",
    calendarEditFormStart:              "Start",
    calendarEditFormEnd:                "Ende",
    calendarEditFormEndDate:            "Bis einschließlich",
    calendarEditFormShowBefore:         "Zeige einen Termin von",
    calendarEditFormRecurrence:         "Wiederholung",
    calendarEditFormRecurrenceDetailed: "Wiederholen Sie diesen Eintrag",
    calendarEditFormInterval:           "Wiederholen",
    calendarEditFormIntervalEndDate:       "Enddatum der Wiederholung",
    calendarEditFormIntervalHasEndDate:    "Hör auf, das Datum zu wiederholen",
    calendarEditFormIntervalHasEndDateYes: "Hör auf, das Datum zu wiederholen",
    calendarEditFormIntervalHasEndDateNo:  "Die Wiederholung hat kein Enddatum",
    calendarEditFormDaysOfWeek:         "WochenTage",
    calendarEditFormDontShowEnd:        "Endzeit nicht anzeigen",
    calendarEditFormDontShowEndYes:     "Endzeit anzeigen",
    calendarEditFormDontShowEndNo:      "Endzeit nicht anzeigen",
    calendarEditFormShowAbout:          "Gegen anzeigen",
    calendarEditFormShowAboutYes:       "Gegen anzeigen",
    calendarEditFormShowAboutNo:        "Gegen nicht anzeigen",
    calendarEditFormOverrideLimit:         "Erinnerungen einschränken",
    calendarEditFormOverrideLimitNo:       "Begrenzen Sie die Erinnerungen auf 3 gleichzeitig",
    calendarEditFormOverrideLimitYes:      "Erinnerung immer anzeigen",

    calendarEditBirthdayName:                "Name",
    calendarEditBirthdayIsCaretaker:         "Pflegeempfänger selbst",
    calendarEditBirthdayIsCaretakerExtended: "Dies ist der Pflegeempfänger selbst",
    calendarEditBirthdayIsCaretakerDisabled: "Ein weiterer Geburtstag wurde bereits als pflegebedürftige Person markiert",
    calendarEditBirthdayBirthday:            "Geburtstag",
    calendarEditBirthdayShowAge:             "Alter",
    calendarEditBirthdayShowAgeYes:          "Alter zeigen",
    calendarEditBirthdayShowAgeNo:           "Alter nicht zeigen",
    calendarEditBirthdayShowPhone:           "Telefonnummer anzeigen",
    calendarEditBirthdayShowPhoneYes:        "Telefonnummer anzeigen",
    calendarEditBirthdayShowPhoneNo:         "Telefonnummer nicht anzeigen",
    calendarEditBirthdayPhoneNumber:         "Telefonnummer",
    calendarEditBirthdayShowBefore:          "Zeige den Geburtstag von",
    calendarEditFormNotification:            "Benachrichtigung",
    calendarEditFormNotificationYes:    "Ton und Blinken aktiv",
    calendarEditFormNotificationNo:     "Kein Benachrichtigung",

    calendarDefaultSettingsTitle:           "Standardeinstellungen für neue Elemente",
    calendarDefaultSettingsForAppointments: "Standardeinstellungen für neue Termine",
    calendarDefaultSettingsForReminders:    "Standardeinstellungen für neue Erinnerungen",
    calendarDefaultSettingsForBirthdays:    "Standardeinstellungen für neue Geburtstage",
    calendarDefaultSettingsShowAbout:       "Gegen anzeigen",
    calendarDefaultSettingsShowAboutYes:    "Gegen anzeigen",
    calendarDefaultSettingsShowAboutNo:     "Gegen nicht anzeigen",
    calendarDefaultSettingsDontShowEnd:     "Endzeit nicht anzeigen",
    calendarDefaultSettingsDontShowEndYes:  "Endzeit anzeigen",
    calendarDefaultSettingsDontShowEndNo:   "Endzeit nicht anzeigen",

    calendarNextTime:             "Nächstes Mal",
    calendarSkipNextTime:         "Nächstes Mal überspringen",
    calendarSkipNextTimeMessage:  "Nächstes Mal überspringen. Dies stellt sicher, dass der Termin beim nächsten Mal nicht auf dem Bildschirm angezeigt wird.",
    calendarSkipSuccess:          "Der Termin wird 1x übersprungen",
    calendarSkipFail:             "Der Termin kann nicht übersprungen werden",
    calendarResetSkip:            "Überspringe den Termin nicht mehr",
    calendarResetSkipMessage:     "Überspringen Sie nicht das nächste Mal. Dadurch wird sichergestellt, dass der Termin am normalen Tag wieder angezeigt und nicht mehr übersprungen wird.",
    calendarResetSkipSuccess:     "Der Termin wird wieder wie ursprünglich geplant angezeigt",
    calendarResetSkipFail:        "Der Termin kann nicht wiederhergestellt werden",
    calendarDifferentDaysTitle:   "Verschiedenen Tagen",
    calendarDifferentDaysWarning: "Der Termin beginnt und endet an verschiedenen Tagen. Dies ist für wiederkehrende Termine nicht erforderlich. Sind Sie sicher, dass Sie das wollen?",
    calendarStartTimeChanged:     "Hinweis: Die Startzeit wurde angepasst, da sie vor der Endzeit lag.",

    calendarOnChannel: " auf ",
    calendarDate:      "Datum",
    calendarTime:      "Zeit",
    calendarWholeDay:  "Den ganzen Tag",

    calendarRecurrences: {
        options:            {
            "none":     "Nicht wiederholen",
            "interval": "Periodisch (alle x Tage)",
            "weekdays": "WochenTage"
        },
        days:               {
            1: "1 Tag",
            2: "2 Tage",
            3: "3 Tage",
            4: "4 Tage",
            5: "5 Tage",
            6: "6 Tage"
        },
        weeks:              {
            1: "1 Woche",
            2: "2 Wochen",
            3: "3 Wochen",
            4: "4  Wochen",
            6: "6 Wochen",
            8: "8 Wochen"
        },
        months:             {
            1: "1 Monat",
            2: "2 Monate",
            3: "3 Monate",
            4: "4 Monate",
            5: "5 Monate",
            6: "6 Monate",
            9: "9 Monate"
        },
        years:              {
            1:  "1 Jahr",
            2:  "2 Jahre",
            3:  "3 Jahre",
            4:  "4 Jahre",
            5:  "5 Jahre",
            10: "10 Jahre"
        },
        every:              {
            day:           "Jeden tag",
            week:          "Jede Woche",
            month:         "Jeden Monat",
            year:          "Jedes Jahr",
            multipleDay:   "Alle %1 Tage",
            multipleWeek:  "Alle %1 Wochen",
            multipleMonth: "Alle %1 Monate",
            multipleYear:  "Alle %1 Jahr",
            weekday:       "Jeder"
        },
        at:                 {
            time:     " um ",
            day:      " auf ",
            dayMonth: " auf dem ",
            starting: " von "
        },
        showBefore:         {
            "15 minutes": "15 Minuten vorher",
            "30 minutes": "30 Minuten vorher",
            "1 hour":     "1 Stunde vorher",
            "2 hours":    "2 Stunden vorher",
            "3 hours":    "3 Stunden vorher",
            "6 hours":    "6 Stunden vorher",
            "12 hours":   "12 Stunden vorher",
            "1 day":      "1 Tag vorher",
            "2 days":     "2 Tage vorher",
            "3 days":     "3 Tage vorher",
            "4 days":     "4 Tage vorher",
            "5 days":     "5 Tage vorher",
            "6 days":     "6 Tage vorher",
            "1 week":     "1 Woche vorher"
        },
        showBirthdayBefore: {
            "15 minutes": "Am Tag selbst",
            "1 day":      "1 Tag vorher",
            "2 days":     "2 Tage vorher",
            "3 days":     "3 Tage vorher",
            "4 days":     "4 Tage vorher",
            "5 days":     "5 Tage vorher",
            "6 days":     "6 Tage vorher"
        },
        interval:           {
            "1 day":    "Jeden Tag",
            "2 days":   "Alle 2 Tage",
            "3 days":   "Alle 3 Tage",
            "4 days":   "Alle 4 Tage",
            "5 days":   "Alle 5 Tage",
            "6 days":   "Alle 6 Tage",
            "1 week":   "Jede Woche",
            "2 weeks":  "Alle 2 Wochen",
            "3 weeks":  "Alle 3 Wochen",
            "4 weeks":  "Alle 4 Wochen",
            "6 weeks":  "Alle 6 Wochen",
            "8 weeks":  "Alle 8 Wochen",
            "1 month":  "Jeden Monat",
            "2 months": "Alle 2 Monate",
            "3 months": "Alle 3 Monate",
            "4 months": "Alle 4 Monate",
            "5 months": "Alle 5 Monate",
            "6 months": "Alle 6 Monate",
            "9 months": "Alle 9 Monate",
            "1 year":   "Jedes Jahr",
            "2 years":  "Alle 2 Jahre",
            "3 years":  "Alle 3 Jahre",
            "4 years":  "Alle 4 Jahre",
            "5 years":  "Alle 5 Jahre",
            "10 years": "Alle 10 Jahre"
        },
        noReoccurance:      "nicht zu wiederholen"
    }
};