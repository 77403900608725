// React
import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import _ from 'underscore';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../store/actions';

// Tools
import i18n from '../../i18n';

// Components
import {Spinner, BottomBar, Tour, Icon} from '../../components';
import NoCaretakers from "./NoCaretakers";
import {TitleWrapper} from "../../contexts/TitleProvider";
import {getPageHelpSteps} from "../../help/tools";
import css from "./Caretakers.module.css";
import {Collapse} from "reactstrap";
import CaretakerItem from "./CaretakerItem";
import GroupItem from "./GroupItem";

const CaretakerCountry = props => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleExpand        = e => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const groupedCities       = _.groupBy(_.sortBy(props.caretakers, 'city'), 'city');
    return (
        <div>
            <div className={[css.button, css.tourCountry].join(" ")} onClick={toggleExpand} data-toggle="collapse" aria-expanded={isOpen}>
                <span className={css.countryName}>
                    <span>{props.countryName}</span>
                    <Icon name="arrow"/>
                </span>
            </div>
            <Collapse isOpen={isOpen}>
                <div className={css.countryCollapse}>
                    {Object.entries(groupedCities).map(([cityName, localCaretakers]) => (
                        <CaretakerCity
                            key={cityName}
                            cityName={cityName}
                            caretakers={localCaretakers}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

const CaretakerCity = props => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleExpand        = e => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    return (
        <div>
            <div className={css.tourCity} onClick={toggleExpand} data-toggle="collapse" aria-expanded={isOpen}>
                <span className={css.cityName}>{props.cityName}</span>
                <Icon name="dropdown"/>
            </div>
            <Collapse isOpen={isOpen}>
                <div className={css.cityCollapse}>
                    {props.caretakers.map(caretaker => <CaretakerItem key={caretaker.uuid} {...caretaker}/>)}
                </div>
            </Collapse>
        </div>
    );
};

const GroupsExpander = props => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleExpand        = e => {e.preventDefault(); setIsOpen(!isOpen);};
    return (
        <div>
            <div className={[css.button, css.tourCountry].join(" ")} onClick={toggleExpand} data-toggle="collapse" aria-expanded={isOpen}>
                <span className={css.countryName}>
                    <span>{i18n.groups}</span>
                    <Icon name="arrow"/>
                </span>
            </div>
            <Collapse isOpen={isOpen}>
                <div className={css.countryCollapse}>
                    {props.groups.map(group => (
                        <GroupItem key={group.uuid} {...group}/>
                    ))}
                </div>
            </Collapse>
        </div>
    );
};


const Caretakers = props => {
    const dispatch                = useDispatch();
    const [helpOpen, setHelpOpen] = useState(false);
    const doHelp                  = () => setHelpOpen(true);
    const closeHelp               = () => setHelpOpen(false);
    const loading                 = useSelector(state => state.caretakers.loading);
    const caretakers              = useSelector(state => state.caretakers.caretakers);
    const groups                  = useSelector(state => state.caretakers.groups);
    const caretakersLoaded        = useSelector(state => state.caretakers.caretakersLoaded);

    useEffect(() => {
        if (!caretakersLoaded || moment().diff(caretakersLoaded) > 300000) {
            dispatch(actions.caretakers.load());
        }
    }, [caretakersLoaded, dispatch, props]);

    if (!caretakers || loading) {
        return <Spinner/>;
    }

    let groupedCountries = _.groupBy(_.sortBy(caretakers, 'country'), 'country');
    let hideLocations    = caretakers.length < 5;
    return (
        <TitleWrapper title={i18n.caretakers}>
            <Tour
                isOpen={helpOpen}
                onRequestClose={closeHelp}
                steps={getPageHelpSteps("caretakers")}
            />
            <div>
                <div className="content-list">
                    {caretakers.length > 5 ?
                        Object.entries(groupedCountries).map(([countryName, localCaretakers]) => (
                            <CaretakerCountry
                                key={countryName}
                                countryName={countryName}
                                caretakers={localCaretakers}
                                hideLocations={hideLocations}/>
                        )) : (
                            caretakers.length > 0 ? caretakers.map(caretaker => <CaretakerItem key={caretaker.uuid} {...caretaker}/>) :
                                <NoCaretakers/>
                        )
                    }
                    {groups.length > 0 && <GroupsExpander groups={groups} />}
                    <BottomBar
                        helpOpen={helpOpen} onHelp={doHelp} onCloseHelp={closeHelp}
                        links={
                            caretakers && caretakers.length === 0 ?
                                [
                                    {title: i18n.caretakersItemAdd, link: "/caretakers/addnewcaretaker"}
                                ] :
                                [
                                    {title: i18n.caretakersItemAdd, link: "/caretakers/addnewcaretaker"},
                                    {title: i18n.caretakersAddNewUser, link: "/caretakers/invitenewuser"}
                                ]
                        }
                    />
                </div>
            </div>
        </TitleWrapper>
    );
};

export default React.memo(Caretakers);
