export default {
    locationsTitle: "All caretaker locations",

    caretakers:   "Caretakers",
    noCaretakers: "No caretakers",

    caretaker:                    "caretaker",
    caretakersTitle:              "Caretakers",
    caretakersTableName:          "Name",
    caretakersTableLocation:      "Location",
    caretakersTableAddress:       "Address",
    caretakersTableZipCode:       "Zip code",
    caretakersTableCity:          "City",
    caretakersTableState:         "Province",
    caretakersTableCountry:       "Country",
    caretakersTableTimeZone:      "Timezone",
    caretakersTableMachines:      "Machines",
    caretakersTableMachinesShort: "M",
    caretakersActionsEdit:        "Edit",
    caretakersActionsDelete:      "Delete",

    caretakersFullTableName:     "Caretaker name",
    caretakersFullTableLocation: "Caretaker location",
    caretakersFullTableAddress:  "Caretaker address",
    caretakersFullTableZipCode:  "Caretaker zip code",
    caretakersFullTableCity:     "Caretaker city",
    caretakersFullTableState:    "Caretaker province",
    caretakersFullTableCountry:  "Caretaker country",

    caretakersAddNewMachine:                "Add a new Spiqle",
    caretakersAddNewMachineTitle:           "Add Spiqle",
    caretakersAddNewMachineScreenCodeLabel: "Screen code",
    caretakersAddNewMachineScreenName:      "Screen name",
    caretakersAddNewMachineStep2:           "Caretaker",
    caretakersAddNewMachineStep3:           "Recap",
    caretakersAddNewMachineScreenCode:      "Enter the code that is shown on the screen",
    caretakersAddNewMachineCaretaker:       "Enter the details of your caretaker or pick an existing caretaker",
    caretakersAddNewMachineSuccess:         "Thew new Spiqle Calendar has been added successfully. You can now set up the contents.",
    caretakersAddNewMachineError:           "An error occurred while coupling the Spiqle Calendar",
    caretakersAddNewMachineInvalidCode:     "Unknown screen code, check the code and try again",
    caretakersAddNewMachineToExisting:      "Add new Spiqle to this caretaker",

    caretakersAddMenu: "Add...",

    caretakersAddNewUser:                  "Invite a new user",
    caretakersAddNewUserExplain:           "You can use this feature to invite other people to get access to your caretakers. For example: a family member or colleague.",
    caretakersAddNewUserName:              "Name",
    caretakersAddNewUserEmail:             "E-mail address",
    caretakersAddNewUserCaretakers:        "Select the caretakers the new user gets access to",
    caretakersAddNewUserRights:            "Select what the new user can do",
    caretakersAddNewUserRightsList:        {
        content: "Calenders",
        users:   "Calenders and invite new users",
        payment: "Calenders and administration"
    },
    caretakersInviteSuccess:               "The new user has been invited successfully",
    caretakersInviteError:                 "An error occurred while inviting the new user",
    caretakersInviteErrorInvitedYourself:  "You can't invite yourself",
    caretakersInviteErrorUnknownRight:     "Unknown user rights",
    caretakersInviteErrorInvalidCaretaker: "Unknown caretaker",

    caretakersItemAdd:         "Add a new caretaker",
    caretakersItemEdit:        "Edit caretaker details",
    caretakersItemEditSuccess: "The caretaker details have been saved successfully",
    caretakersItemEditError:   "An error occurred while saving the caretaker details",

    caretakersAddMachine: "Add Spiqle screen",

    caretakersMessage:              "Direct message",
    caretakersMessageTo:            "Direct message to %1",
    caretakersMessageSentTo:        "Send a message to %1",
    caretakersMessageMessage:       "Message",
    caretakersMessageDuration:      "Visible for",
    caretakersMessageDurations:     {
        300:   "5 minutes",
        900:   "15 minutes",
        1800:  "30 minutes",
        3600:  "1 hour",
        10800: "3 hours",
        21600: "6 hours",
        43200: "12 hours",
        86400: "24 hours"
    },
    caretakersMessageUrgent:        "Urgent message",
    caretakersMessageSuccess:       "Direct message sent successfully",
    caretakersMessageError:         "An error occurred while sending the direct message",
    caretakersMessageCancelSuccess: "Direct message successfully removed from the screen",
    caretakersMessageCancelError:   "An error occurred while removing the message",
    caretakersMessageNoMachines:    "This caretaker has no Spiqle Calendar yet",
    buttonCancelMessage:            "Cancel message (remove from the screen)",

    caretakersSentMessages:          "Sent messages",
    caretakersSentMessagesMessage:   "Message",
    caretakersSentMessagesSentLong:  "Sent",
    caretakersSentMessagesSentShort: "Sent",
    caretakersSentMessagesSentBy:    "Sent by",
    caretakersSentMessagesDelivered: "Shown on screen",
    caretakersSentMessagesCancelled: "Message cancelled",
    caretakersNoSentMessages:        "No messages sent yet",

    caretakersItemCalendar:                      "Calendar",
    caretakersItemCalendars:                     "Calendars",
    caretakersItemCalendarsDefaultSettings:      "Default settings for calendars",
    caretakersItemCalendarsEditAppointments:     "Manage appointments",
    caretakersItemCalendarsEditBirthdays:        "Manage birthdays",
    caretakersItemCalendarsEditTvShows:          "Manage TV programs",
    caretakersItemCalendarsEditReminders:        "Manage reminders",
    caretakersItemCalendarsEditItemAppointments: "Edit appointment",
    caretakersItemCalendarsEditItemBirthdays:    "Edit birthday",
    caretakersItemCalendarsEditItemTvShows:      "Edit TV program",
    caretakersItemCalendarsEditItemReminders:    "Edit reminder",
    caretakersItemCalendarsFor:                  "Manage calendars for %1",
    caretakersItemPreview:                       "Screen preview",
    caretakersItemPreviewFor:                    "Screen preview for %1",
    caretakersItemMachines:                      "Manage machines for %1",
    caretakersItemMachinesTitle:                 "Manage machines",
    caretakersItemMachinesAdd:                   "Couple machine to this caretaker",
    caretakersSelectCaretaker:                   "Select a caretaker",
    caretakerChooseAddOrExisting:                "Select an existing caretaker or add a new one",
    caretakerCreateNewTitle:                     "Enter the details for the new caretaker",
    caretakerCreateNewCaretaker:                 "Add new caretaker",

    caretakersRemoveTitle:   "Remove caretaker",
    caretakersRemoveBody:    "If you remove the caretaker named '%1', all screens coupled to this caretaker will be uncoupled. This can not be undone.",
    caretakersRemoveSure:    "Remove caretaker",
    caretakersRemoveSuccess: "The caretaker has been removed successfully",
    caretakersRemoveError:   "An error occurred while removing the caretaker",

    caretakersDayOverview:      "Day overview",
    caretakersDayOverviewFor:   "Day overview for %1",
    caretakersWeekOverviewFor:  "Week overview for %1",
    caretakersMonthOverviewFor: "Month overview for %1",
    caretakersOverviewTime:     "Time",
    caretakersOverviewNoItems:  "There are no calendar items for this day",

    caretakerSettings:    "Settings",
    caretakerSettingsFor: "Manage the settings for %1",

    caretakerDetails:           "Details %1",
    caretakerScreenSettings:    "Screen settings",
    caretakerScreenStatus:      "Spiqle screens",
    caretakerScreenStatusTitle: "Screens %1",
    caretakerScreenName:        "Name screen:",
    caretakerScreenStatusHead:  "Status screen:",

    caretakerSettingsTheme:                          "Theme",
    caretakerSettingsThemeValues:                    {
        black:               "Black",
        blackboard:          "Blackboard",
        emeraldgreen:        "Emerald green",
        lemongrass:          "Lemongrass",
        nightblue:           "Night blue",
        papaver:             "Papaver",
        pumpkin:             "Pumpkin",
        steelblue:           "Steelblue",
        yellow:              "Contrast yellow",
        sockgreenolive:      "Green sock: Olive",
        sockgreenkhaki:      "Green sock: Khaki",
        sockdewcorn:         "Dew sock: Corn",
        sockdewwheat:        "Dew sock: Wheat",
        sockperuyellowbrown: "Peru sock: Yellow brown",
        sockperusienna:      "Peru sock: Sienna",
        modernblue:          "Modern - Blue",
        modernbrown:         "Modern - Brown",
        moderngreen:         "Modern - Green",
        moderndeepsea:       "Modern - Deep sea",
        modernblack:         "Modern - Black",
    },
    caretakerSettingsFont:                           "Font",
    caretakerSettingsFontValues:                     {
        helvetica: "Helvetica",
        calibri:   "Calibri",
        gillsans:  "Gill sans"
    },
    caretakerSettingsLanguage:                       "Language",
    caretakerSettingsLanguageValues:                 {
        nl: "Dutch",
        da: "Danish",
        de: "German",
        en: "English"
    },
    caretakerSettingsGreeting:                       "Greeting",
    caretakerSettingsClock:                          "Clock",
    caretakerSettingsClockValues:                    {
        digital:    "Digital  11:00",
        digitalext: "Digital  11:00 Morning",
        analog:     "Analog  🕘",
        written:    "Written (Five past eleven)"
    },
    caretakerSettingsDAT:                            "Day after tomorrow",
    caretakerSettingsDATValues:                      {
        dat:     "Day after tomorrow",
        "2days": "In 2 days"
    },
    caretakerSettingsHours:                          "24 / 12 hour clock",
    caretakerSettingsHoursValues:                    {
        12: "12 hour clock (quarter past 2)",
        24: "24 hour clock (14:15)"
    },
    caretakerSettingsSpeed:                          "Text speed",
    caretakerSettingsSpeedValues:                    {
        1: "Fast (10 seconds)",
        2: "Normal (20 seconds)",
        3: "Slow (40 seconds)",
        4: "Really slow (60 seconds)",
        5: "Even slower (120 seconds)"
    },
    caretakerSettingsTomorrowDay:                    "Tomorrow",
    caretakerSettingsTomorrowDayYes:                 "Show the day if the appointment is tomorrow",
    caretakerSettingsTomorrowDayNo:                  "Don't show the day if the appointment is tomorrow",
    caretakerSettingsItIsToday:                      "Show today",
    caretakerSettingsItIsTodayYes:                   "Show 'today' in the greeting",
    caretakerSettingsItIsTodayNo:                    "Don't show 'today' in the greeting",
    caretakerSettingsShowHourToday:                  "Show 'hour' after time",
    caretakerSettingsShowHourTodayYes:               "Show 'hour' after time",
    caretakerSettingsShowHourTodayNo:                "Don't show 'hour' after time",
    caretakerSettingsShowCountdown:                  "Countdown to appointment (x hours left)",
    caretakerSettingsShowCountdownYes:               "Countdown to appointment (x hours left)",
    caretakerSettingsShowCountdownNo:                "Do not countdown to appointment",
    caretakerSettingsShowWeather:                    "Weather for today",
    caretakerSettingsShowWeatherYes:                 "Show the current weather",
    caretakerSettingsShowWeatherNo:                  "Don't show the current weather",
    caretakerSettingsTomorrowWeather:                "Weather for tomorrow",
    caretakerSettingsTomorrowWeatherYes:             "Show the weather for tomorrow",
    caretakerSettingsTomorrowWeatherNo:              "Don't show the weather for tomorrow",
    caretakerSettingsPublicHolidaysShowBefore:       "Show public holidays from",
    caretakerSettingsPublicHolidaysShowBeforeValues: {
        "15 minutes": "The day itself",
        "1 day":      "1 day before",
        "2 days":     "2 days before",
        "3 days":     "3 days before",
        "4 days":     "4 days before",
        "5 days":     "5 days before",
        "6 days":     "6 days before",
        "1 week":     "1 week before"
    },
    caretakerSettingsPublicHolidaysCountry:          "Show public holidays for",
    caretakerSettingsPublicHolidaysCountryValues:    {
        nl: "The Netherlands",
        be: "Belgium",
        da: "Denmark",
        de: "Germany",
        uk: "Great Britain",
        ch: "Switzerland"
    },

    caretakerSettingsPublicHolidays:    "Public holidays",
    caretakerSettingsPublicHolidaysYes: "Show public holidays",
    caretakerSettingsPublicHolidaysNo:  "Don't show public holidays",

    caretakerSettingsNotificationSound: "Notification sound",
    caretakerSettingsNotificationYes:   "Use sound with notifications",
    caretakerSettingsNotificationNo:    "Don't use sound with notifications",

    caretakerSettingsErrorLoad:   "An error occurred while loading the caretakers settings",
    caretakerSettingsErrorSave:   "An error occurred while saving the caretakers settings",
    caretakerSettingsSuccessSave: "The caretakers settings have been saved successfully",

    caretakerDefaultsErrorLoad:   "An error occurred while loading the caretakers default settings",
    caretakerDefaultsErrorSave:   "An error occurred while saving the caretakers default settings",
    caretakerDefaultsSuccessSave: "The default settings for the caretaker have been saved successfully",

    caretakerMachineNameErrorSave:   "An error occurred while saving the name of the screen",
    caretakerMachineNameSuccessSave: "The name of the screen has been saved successfully",

    caretakerName:            "Name",
    caretakerAddress:         "Address",
    caretakerZipcode:         "Zipcode",
    caretakerCity:            "City",
    caretakerState:           "Province",
    caretakerCountry:         "Country",
    caretakerPhone:           "Phone number",
    caretakerPhoto:           "Photo",
    caretakerPhotoPick:       "Pick different photo",
    caretakerPhotoRemove:     "Remove photo",
    caretakerBirthday:        "Birthday",
    caretakerShowBirthday:    "Show birthday in calendar",
    caretakerShowBirthdayYes: "Show in calendar",
    caretakerShowBirthdayNo:  "Don't show in calendar",

    errorCaretakersLoad:         "An error occurred while loading the caretakers",
    caretakersLoadMachinesError: "An error occurred while loading the caretakers machines",

    photoAdd:    "Add",
    photoEdit:   "Replace",
    photoDelete: "Remove",
};