import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap, load, loadFail, save, saveDone, saveFail, resetError} from "../reducerFunctions";

const initialState = {
    loading:      false,
    saving:       false,
    error:        null,
    groups:       null,
    groupsLoaded: null
};

const loadSuccess = (state, action) => ({...state, loading: false, error: null, groups: action.groups, groupsLoaded: moment()});

const actionMap     = {
    [actionTypes.GROUPS_LOAD]:                      load,
    [actionTypes.GROUPS_LOAD_SUCCESS]:              loadSuccess,
    [actionTypes.GROUPS_LOAD_FAIL]:                 loadFail,
    [actionTypes.GROUPS_SAVE]:                      save,
    [actionTypes.GROUPS_SAVE_SUCCESS]:              saveDone,
    [actionTypes.GROUPS_SAVE_FAIL]:                 saveFail,
    [actionTypes.GROUPS_REMOVE]:                    save,
    [actionTypes.GROUPS_REMOVE_SUCCESS]:            saveDone,
    [actionTypes.GROUPS_REMOVE_FAIL]:               saveFail,
    [actionTypes.GROUPS_ADD_CARETAKERS]:            save,
    [actionTypes.GROUPS_ADD_CARETAKERS_SUCCESS]:    saveDone,
    [actionTypes.GROUPS_ADD_CARETAKERS_FAIL]:       saveFail,
    [actionTypes.GROUPS_MANAGE_SAVE]:               save,
    [actionTypes.GROUPS_MANAGE_SAVE_SUCCESS]:       saveDone,
    [actionTypes.GROUPS_MANAGE_SAVE_FAIL]:          saveFail,
    [actionTypes.GROUPS_MANAGE_CARETAKERS]:         save,
    [actionTypes.GROUPS_MANAGE_CARETAKERS_SUCCESS]: saveDone,
    [actionTypes.GROUPS_MANAGE_CARETAKERS_FAIL]:    saveFail,
    [actionTypes.GROUPS_REMOVE_CARETAKER]:          save,
    [actionTypes.GROUPS_REMOVE_CARETAKER_SUCCESS]:  saveDone,
    [actionTypes.GROUPS_REMOVE_CARETAKER_FAIL]:     saveFail,
    [actionTypes.GROUPS_RESET_ERROR]:               resetError
};
const groupsReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default groupsReducer;
