import i18n from "../../i18n";
import {defaultColumns, accessorColumn} from "./funcs";

export const inviteColumns = {
    type: accessorColumn("type", i18n.rightType, d => i18n.usersRightsTypeMapping[d.type], {width: 110, togglable: false}),
    name: accessorColumn("name", i18n.caretakersTableName, d => d.info.name, {togglable: false})
};

export const inviteColumnsDefault = (actions = null, selector = null) => defaultColumns(
    [
        inviteColumns.type,
        inviteColumns.name
    ], actions, "uuid", selector
);
