import * as actionTypes from "../actionTypes";
import moment from 'moment-timezone';
import {runActionMap} from "../reducerFunctions";

const initialState = {
    lastUniversalLink: null,
    lastLinkReceived: null
};

// Load reducers
const receivedUniversalLink = (state, action) => ({
    ...state,
    lastUniversalLink: action.data,
    lastLinkReceived: moment()
});

const actionMap = {
    [actionTypes.APP_RECEIVED_UNIVERSAL_LINK]: receivedUniversalLink,
};
const appReducer = (state = initialState, action) => runActionMap(state, action, actionMap);

export default appReducer;
