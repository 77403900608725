import {actionColumn, standardColumn, selectorColumn} from "./funcs";
import {affiliateColumns, affiliateColumnsDefault} from "./affiliates";
import {caretakerColumns, caretakerColumnsDefault, caretakerColumnsSimple} from "./caretakers";
import {calendarOverviewColumns, calendarOverviewColumnsDefault} from "./calendarOverview";
import {distributorColumns, distributorColumnsDefault} from "./distributors";
import {machineColumns, machineColumnsDefaultRedux, machineColumnsDefaultCaretaker} from "./machines";
import {updateColumns, updateColumnsDefault} from "./updates";
import {userColumns, userColumnsDefault} from "./users";
import {groupColumns, groupColumnsDefault} from "./groups";
import {inviteColumns, inviteColumnsDefault} from "./invites";

export default {
    standardColumn,
    actionColumn,
    selectorColumn,
    affiliateColumns,
    affiliateColumnsDefault,
    caretakerColumns,
    caretakerColumnsDefault,
    caretakerColumnsSimple,
    calendarOverviewColumns,
    calendarOverviewColumnsDefault,
    distributorColumns,
    distributorColumnsDefault,
    groupColumns,
    groupColumnsDefault,
    inviteColumns,
    inviteColumnsDefault,
    machineColumns,
    machineColumnsDefaultRedux,
    machineColumnsDefaultCaretaker,
    updateColumns,
    updateColumnsDefault,
    userColumns,
    userColumnsDefault
};